import http, { throwOnAxiosError } from './http'

export async function sendFeedback({ rating, comment }) {
  try {
    const res = await http.post('/v1/me/feedback', {
      rating,
      comment,
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to submit feedback')
  }
}
