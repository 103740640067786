import { convertV2toV1limits } from './limits'
import { updateRowColors } from './matrixColors'
import { addPoints, sum } from './points'

type PartialPointsSummaryRow = {
  name: string
  given: {
    points: DifficultyPoint
    rowTotal?: number
  }
  available: {
    points: DifficultyPoint
    rowTotal?: number
  }
}

type UnsortedPointsRow = PartialPointsSummaryRow & {
  sort: number
  key?: string
}

export type Ability = {
  id: string
  name: string
  description?: string
}

export function abilityMatrix(
  criterias: QuestionCriteriaWithPoints[],
  abilities: Ability[],
  limits: LimitsV2[][],
  connectedAbilities: Record<string, number>,
  skipBlankRows: boolean
) {
  const map = criterias.reduce(
    (
      map: Map<string, UnsortedPointsRow>,
      criteria: QuestionCriteriaWithPoints
    ) => {
      const abilityKey = criteria.abilityKey
      map.set(abilityKey, {
        given: {
          points: addPoints(
            map.get(abilityKey)?.given.points || [0, 0, 0],
            criteria.givenPoints
          ),
        },
        available: {
          points: addPoints(
            map.get(abilityKey)?.available.points || [0, 0, 0],
            criteria.availablePoints
          ),
        },
        key: abilityKey,
        name: criteria.ability,
        sort: 0, // FIXME check this
      })
      return map
    },
    new Map()
  )

  if (Object.keys(connectedAbilities).length === 0) {
    return addColorField(sortAndAddTotals(map))
  }

  const connectedMap = applyConnectedAbilities(
    map,
    abilities,
    connectedAbilities
  )

  const withColors = applyLimitColors(sortAndAddTotals(connectedMap), limits)
  if (skipBlankRows) {
    return withColors.filter((row) => row.available.points[0] !== 0)
  }
  return withColors
}

function applyConnectedAbilities(
  map: Map<string, UnsortedPointsRow>,
  abilities: Ability[],
  connectedAbilities: Record<string, number>
): Map<number, UnsortedPointsRow> {
  const pointMap = new Map<number, UnsortedPointsRow>()
  Object.keys(connectedAbilities).forEach((id) => {
    const index = connectedAbilities[id]
    const abilityName = abilities.find((a) => a.id === id)?.name || ''
    const currentObject = pointMap.get(index)
    if (!currentObject) {
      pointMap.set(index, {
        given: {
          points: [0, 0, 0],
        },
        available: {
          points: [0, 0, 0],
        },
        name: abilityName,
        sort: index,
      })
    } else {
      pointMap.set(index, {
        given: {
          points: [0, 0, 0],
        },
        available: {
          points: [0, 0, 0],
        },
        name: `${currentObject.name} & ${abilityName}`,
        sort: index,
      })
    }
  })
  const abilitiesWithPoints = Array.from(map.values()).reduce((acc, obj) => {
    const index = connectedAbilities[obj.key ?? '']
    const currentObject = acc.get(index)
    if (currentObject) {
      currentObject.given.points = addPoints(
        currentObject.given.points,
        obj.given.points
      )
      currentObject.available.points = addPoints(
        currentObject.available.points,
        obj.available.points
      )
    }
    return acc
  }, pointMap)
  return abilitiesWithPoints
}

function sortAndAddTotals(
  map: Map<string | number, UnsortedPointsRow>
): PointsSummaryMatrix {
  const sorted = Array.from(map.values())
    .sort((a, b) => a.sort - b.sort)
    .map((row) => {
      // remove sort key
      const sortedRow: PartialPointsSummaryRow = {
        name: row.name,
        given: row.given,
        available: row.available,
      }
      return sortedRow
    })

  const givenMatrixTotal = sorted.reduce(
    (acc, subchapterRow) => {
      return addPoints(acc, subchapterRow.given.points)
    },
    [0, 0, 0] as DifficultyPoint
  )
  const availableMatrixTotal = sorted.reduce(
    (acc, subchapterRow) => {
      return addPoints(acc, subchapterRow.available.points)
    },
    [0, 0, 0] as DifficultyPoint
  )
  sorted.push({
    given: {
      points: givenMatrixTotal,
    },
    available: {
      points: availableMatrixTotal,
    },
    name: '',
  })

  const givenTotalColumn = sorted.reduce((acc, sortedRow) => {
    const points = sum(sortedRow.given.points)
    return [...acc, points]
  }, [] as number[])

  const availableTotalColumn = sorted.reduce((acc, sortedRow) => {
    const availablePoints = sum(sortedRow.available.points)
    return [...acc, availablePoints]
  }, [] as number[])

  return sorted.map((subchapterRow, index) => {
    const row: PointsSummaryRow = {
      name: subchapterRow.name,
      given: {
        points: subchapterRow.given.points,
        rowTotal: givenTotalColumn[index],
      },
      available: {
        points: subchapterRow.available.points,
        rowTotal: availableTotalColumn[index],
      },
    }
    return row
  })
}
type QuestionWithPoints = Question & { givenPoints: DifficultyPoint }

export function wrapQuestionsWithPoints(
  questions: Question[],
  points: DifficultyPoint[]
): QuestionWithPoints[] {
  return questions.map((q, i) => ({ ...q, givenPoints: points[i] }))
}

export function wrapQuestionsWithAllPointsGiven(
  questions: Question[]
): QuestionWithPoints[] {
  return questions.map((q) => ({
    ...q,
    givenPoints: q.availablePoints,
  }))
}

export function subchapterMatrix(
  questions: QuestionWithPoints[],
  limits: LimitsV2[][],
  sorting: sortedSubchapter[]
): PointsSummaryMatrix {
  const map = questions
    .map((q) => ({
      subchapterId: q.context.subchapter?.id || 0,
      subchapterName: q.context.subchapter?.name || '',
      givenPoints: q.givenPoints,
      availablePoints: q.availablePoints,
    }))
    .reduce((map, obj) => {
      map.set(obj.subchapterId, {
        sort:
          sorting.find((s: any) => s.subchapterId === obj.subchapterId)
            ?.globalSort || 100000000,
        given: {
          points: addPoints(
            map.get(obj.subchapterId)?.given.points || [0, 0, 0],
            obj.givenPoints
          ),
        },
        available: {
          points: addPoints(
            map.get(obj.subchapterId)?.available.points || [0, 0, 0],
            obj.availablePoints
          ),
        },
        name: obj.subchapterName,
      })
      return map
    }, new Map<number, UnsortedPointsRow>())
  if (limits.length === 0) {
    return addColorField(sortAndAddTotals(map))
  } else {
    return applyLimitColors(sortAndAddTotals(map), limits)
  }
}

function addColorField(matrix: PointsSummaryMatrix): PointsSummaryMatrix {
  return matrix.map((row) => {
    row.colors = ['transparent', 'transparent', 'transparent']
    return row
  })
}

function applyLimitColors(
  matrix: PointsSummaryMatrix,
  limits: LimitsV2[][]
): PointsSummaryMatrix {
  const otg = { e: 1, c: 1, a: 1 } // otg = ok to green
  const limitsV1 = convertV2toV1limits(limits)
  return matrix.map((row, index) => {
    const colors = { e: 'white', c: 'white', a: 'white' }
    const points = {
      e: row.given.points[0],
      c: row.given.points[1],
      a: row.given.points[2],
    }
    updateRowColors(
      colors,
      points,
      index === matrix.length - 1,
      limitsV1[index],
      otg
    )
    row.colors = [colors.e, colors.c, colors.a]
    return row
  })
}
