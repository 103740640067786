import http, { throwOnAxiosError } from './http'
import { UserFacingError } from '@/utils/UserFacingError'
import { PostRedirect } from '@/utils/PostRedirect'
import { PageCodeStatus } from '@/constants'

export async function examGetById(id) {
  try {
    const res = await http.get(`/v1/me/exam/${id}`)
    return res.data
  } catch (err) {
    if (err.response.status === 404) {
      throw new UserFacingError(err, PageCodeStatus.NOT_FOUND, false)
    } else {
      throwOnAxiosError(err, 'Failed to retrieve exam')
    }
  }
}

export async function examCreate(exam) {
  try {
    const res = await http.post(`/v1/me/exam/`, exam)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to create exam')
  }
}

export async function examSave(id, exam) {
  try {
    const res = await http.put(`/v1/me/exam/${id}`, exam)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to save exam')
  }
}

export async function examDelete(id) {
  try {
    await http.delete(`/v1/me/exam/${id}`)
  } catch (err) {
    throwOnAxiosError(err, 'Failed to delete exam')
  }
}

export async function examStatusGet(id) {
  try {
    const res = await http.get(`/v1/me/exam/${id}/status`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to get exam status')
  }
}

export async function examExport(exam) {
  try {
    const res = await http.post(`/v1/me/exam/export`, exam)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to export exam')
  }
}

export async function examUpdate(exam) {
  try {
    const res = await http.patch(`/v1/me/exam/export/update`, exam)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update exam')
  }
}

export async function mapExamPreview(exam) {
  try {
    const response = await http.post(`/v1/me/exam/export/map`, exam)
    return response.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to export exam')
  }
}

// When we export an unsaved draft we use default parameter
export async function examPreview(id = 0, exam) {
  const mappedExam = await mapExamPreview(exam)
  mappedExam.questions = exam.questions
  mappedExam.partSetting = exam.settings
  PostRedirect({
    url: `${import.meta.env.VITE_EXAM_BASE_URL}/gauss/v2/preview`,
    newTab: true,
    data: {
      examId: id,
      exam: JSON.stringify(mappedExam),
    },
  })
}
