import http, { throwOnAxiosError } from './http'

export async function bookGetMyBooks() {
  try {
    const res = await http.get(`/v1/me/books`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve my books')
  }
}

export async function bookFindByCourseId(courseId) {
  try {
    const res = await http.get(`/v1/course/${courseId}`)
    const books = res.data.children
    return Object.keys(books)
      .map((key) => ({ id: key, ...books[key] }))
      .filter((c) => c.type === 'BOOK')
  } catch (err) {
    throwOnAxiosError(err, 'Failed to list books')
  }
}

export async function bookGetById(id) {
  try {
    const res = await http.get(`/v1/book/node/${id}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve book')
  }
}

export async function bookSortingGetById(id) {
  try {
    const res = await http.get(`/v1/book/node/${id}/sorting`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve book sorting')
  }
}

export async function bookCreate(courseId, book) {
  try {
    const res = await http.post(`/v1/course/${courseId}/BOOK`, book)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to create book')
  }
}

export async function bookUpdateNode(nodeId, update) {
  try {
    const res = await http.patch(`/v1/book/node/${nodeId}`, update)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update node')
  }
}

export async function bookDeleteNode(nodeId) {
  try {
    const res = await http.delete(`/v1/book/node/${nodeId}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to delete node')
  }
}

export async function bookCreateChapter(bookId, chapter) {
  try {
    const res = await http.post(`/v1/book/node/${bookId}/CHAPTER`, chapter)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to create chapter')
  }
}

export async function bookCreateSubchapter(chapterId, subchapter) {
  try {
    const res = await http.post(
      `/v1/book/node/${chapterId}/SUBCHAPTER`,
      subchapter
    )
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to create subchapter')
  }
}

export async function bookAddQuestion(subchapterId, questionId) {
  try {
    const res = await http.post(
      `/v1/book/node/${subchapterId}/question/${questionId}`
    )
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to add question')
  }
}

export async function bookDeleteQuestion(subchapterId, questionId) {
  try {
    const res = await http.delete(
      `/v1/book/node/${subchapterId}/question/${questionId}`
    )
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to remove question from subchapter')
  }
}
