import http, { throwOnAxiosError } from './http'

export async function courseGetById(courseId) {
  try {
    const res = await http.get(`/v1/course/${courseId}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve course')
  }
}

export async function courseSettingsGetById(courseId) {
  try {
    const res = await http.get(`/v1/me/courses/${courseId}/settings`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve course settings')
  }
}

export async function getMyCourses() {
  try {
    const res = await http.get(`/v1/me/courses`)
    return res.data.courses
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve my courses')
  }
}
