import axios from 'axios'
import { useTokenStore } from '@/stores/token'
import { useAdminTokenStore } from '@/admin/stores/token'
import { UserFacingError } from '@/utils/UserFacingError'

let tokenType = ''

axios.defaults.params = {}
const http = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: import.meta.env.VITE_API_BASE_URL || '/api',
})

http.interceptors.request.use(
  (config) => {
    let token
    if (tokenType === 'admin') {
      token = useAdminTokenStore()
    } else {
      token = useTokenStore()
    }
    if (token?.hasToken) {
      config.headers.Authorization = 'Bearer ' + token.token
    }
    return config
  },
  (error) => {
    return Promise.reject(
      new UserFacingError(
        error,
        'Network error',
        false,
        'generic.error.network'
      )
    )
  }
)

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (typeof error.response === 'undefined') {
      return Promise.reject(
        new UserFacingError(
          error,
          'Network error',
          false,
          'generic.error.network'
        )
      )
    }
    if ([401, 403].includes(error.response.status)) {
      const message = 'Session expired'

      return Promise.reject(
        new UserFacingError(
          error,
          message,
          false,
          'generic.error.auth',
          error.response.status
        )
      )
    }
    return Promise.reject(error)
  }
)

export function throwOnAxiosError(error, message = 'Something went wrong') {
  if (error instanceof UserFacingError) {
    throw error
  }
  throw new UserFacingError(error, message)
}

export function setTokenType(type) {
  tokenType = type
}

export function currentTokenType() {
  return tokenType
}

export default http
