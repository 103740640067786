import { defineStore } from 'pinia'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'
import { useSessionCacheStore } from '@/stores/sessionCache'
import { getUser } from '@/services/user'
import {
  settingsGetAll,
  settingsUpdatePartial,
  settingsSetCurriculum,
} from '@/services/settings'
import { UserPermissions } from '@/constants'
import { API } from '@/km/services/API'
import kmExamFacade from '@/km/services/KMExamFacade'
import examFacade from '@/services/ExamFacade'
import Bugsnag from '@bugsnag/js'

const BLANK_USER: KMUserType = {
  userId: 0,
  firstName: '',
  lastName: '',
  fullName: '',
  country: '',
  language: 'en',
  status: '',
  permissions: [],
  organization: { id: 0, name: '' },
  returnUrl: '',
  settings: {
    curriculumId: 0,
  },
}

export const useUserStore = defineStore('user', {
  state: () => ({
    gaussUser: useLocalStorage('gaussUser', null as UserType | null, {
      serializer: StorageSerializers.object,
    }),
    kmUser: useLocalStorage('kmUser', null as KMUserType | null, {
      serializer: StorageSerializers.object,
    }),
    // set before each route change
    km: null as boolean | null,
    dirty: false,
  }),
  getters: {
    user(state): UserType | KMUserType {
      const user = state.km ? state.kmUser : state.gaussUser
      if (!user) {
        return BLANK_USER
      }
      return user
    },
    userSettings(): UserSettings {
      return this.user.settings
    },
    hasUnsavedChanges(): boolean {
      return this.dirty
    },
    returnUrl(state) {
      return state.kmUser?.returnUrl
    },
    facade(state) {
      return state.km ? kmExamFacade : examFacade
    },
    allowedToSeeMetadata(): boolean {
      return this.user.permissions.includes(UserPermissions.ViewMetadata)
    },
    isStudent(): boolean {
      return this.user.status === 'STUDENT'
    },
    isTeacher(): boolean {
      return this.user.status === 'TEACHER'
    },
    haveToChooseCurriculum(): boolean {
      return !this.user.settings.curriculumId
    },
  },
  actions: {
    userHasPermission(permission: UserPermissions): boolean {
      return this.user?.permissions.includes(permission) ?? false
    },
    async setKMMode(km: boolean) {
      this.km = km
      await useSessionCacheStore().updateCache()
    },
    async loadGaussUser() {
      const userData = await getUser()
      this.gaussUser = {
        userId: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        fullName: `${userData.firstName} ${userData.lastName}`,
        country: userData.countryId,
        language: userData.languageId,
        status: userData.status,
        permissions: userData.permissions.map((p: any) => p.name),
        organization: userData.organizations.map((o: any) => ({
          id: o.id,
          name: o.name,
        }))[0],
        settings: {
          curriculumId: 0,
          newsletter: false,
          adaptedColors: false,
          anonymizeStudents: false,
          teacherVerification: false,
        },
      }
      await this.loadUserSettings()
      await useSessionCacheStore().onLogin()

      Bugsnag.setUser(String(this.gaussUser.userId))
    },
    async loadKMUser(teacherId: number, schoolId: number, returnUrl: string) {
      const response = await API.getTeacher(teacherId)
      const teacherData = response.data.sqldata[0]
      const permissions = [UserPermissions.EditQuestions]
      if (teacherData.trust >= 2) {
        permissions.push(UserPermissions.EditAllQuestions)
      }
      if (teacherData.trust >= 8) {
        permissions.push(UserPermissions.ViewMetadata)
      }
      this.kmUser = {
        userId: teacherId,
        firstName: teacherData.firstname,
        lastName: teacherData.lastname,
        fullName: `${teacherData.firstname} ${teacherData.lastname}`,
        country: 'sv',
        language: 'sv',
        status: 'TEACHER',
        permissions,
        organization: { id: schoolId, name: 'FIXME' },
        returnUrl,
        settings: {
          curriculumId: 1,
        },
      }
      await useSessionCacheStore().onLogin()
      Bugsnag.setUser(String(teacherId))
    },
    async getCurrentCurriculum() {
      if (!this.user.settings?.curriculumId) {
        return null
      }
      try {
        return await this.facade.getCurriculumById(
          this.user.settings.curriculumId
        )
      } catch (e: any) {
        if (e?.status === 404) {
          // User has a bad setting, reset it
          this.user.settings.curriculumId = 0
        } else {
          throw e
        }
      }
    },
    async setMyCurriculum(curriculumId: number) {
      if (this.user.settings.curriculumId !== Number(curriculumId)) {
        await settingsSetCurriculum(curriculumId)
        const sessionCacheStore = useSessionCacheStore()
        this.user.settings.curriculumId = curriculumId
        await sessionCacheStore.updateCurriculum()
      }
    },
    async loadUserSettings() {
      const settings = await settingsGetAll()
      this.user.settings = settings
    },
    updateSettings(field: string[], value: any) {
      if (!field || field.length === 0 || !Array.isArray(field)) {
        throw new Error('Bad field specification when updating settings')
      }

      // Access the last element directly without modifying the array
      const leaf = field[field.length - 1]

      // Proceed with the rest of your logic
      let obj = this.user.settings
      for (let i = 0; i < field.length - 1; i++) {
        if (!(field[i] in obj)) {
          throw new Error(`Settings field not found: ${field[i]}`)
        }
        obj = obj[field[i]]
      }

      // Update the value
      obj[leaf] = value
      this.dirty = true
    },
    async saveUserSettings() {
      console.log('saveUserSettings')
      await settingsUpdatePartial(this.user.settings)
    },
    setDirtyState(value: boolean) {
      this.dirty = value
    },
    clear() {
      if (this.km) {
        this.kmUser = null
      } else {
        this.gaussUser = null
      }
    },
  },
})
