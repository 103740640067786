import { useAdminTokenStore } from '@/admin/stores/token'

export function useAdminAuthentication() {
  return {
    async beforeEnter(to, from, next) {
      const token = useAdminTokenStore()
      if (token.hasValidToken()) {
        next()
      } else {
        next('/admauth')
      }
    },
  }
}
