import http, { throwOnAxiosError } from './http'

export async function getUser() {
  try {
    const response = await http.get(`/v1/me/profile/`)
    return response.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve user data')
  }
}
