import { defineStore } from 'pinia'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'

export const usePrintStore = defineStore('print', {
  state: () => ({
    exam: useLocalStorage('print', null as Exam | null, {
      serializer: StorageSerializers.object,
    }),
  }),
  actions: {
    setExam(exam: Exam) {
      const examDeepCopy = JSON.parse(JSON.stringify(exam))
      this.exam = examDeepCopy
    },
    clear() {
      this.exam = null
    },
  },
})
