export function updateRowColors(color, points, totalRow, limit, otg) {
  Object.entries(points).map(([level]) => {
    const levelIndex = ['e', 'c', 'a']
    const i = levelIndex.findIndex((x) => x === level)
    const totalPointsAbility = Object.values(points).reduce(
      (sum, num) => (sum = sum + num),
      0
    )

    const b = Object.freeze({
      totalPoint: limit[i][2],
      minimumTotalPointsToYellow: limit[i][0][0],
      minimumPointsOnGradeOrHigherToYellow: limit[i][0][1],
      minimumTotalPointsToGreen: limit[i][1][0],
      minimumPointsOnGradeOrHigherToGreen: limit[i][1][1],
    })
    const e = Object.freeze({
      noPoints: b.totalPoint === 0,
      oneTotalPointButMinimumTotalPointsToGreen:
        b.totalPoint === 1 && totalPointsAbility >= b.minimumTotalPointsToGreen,
      oneTotalPointButStudentHasZeroPoints:
        b.totalPoint === 1 && totalPointsAbility === 0,
      moreThanOneTotalPointAndMinimumTotalPointsToGreen:
        totalPointsAbility >= b.minimumTotalPointsToGreen,
      moreThanOneTotalPointAndMinimumTotalPointsToYellow:
        totalPointsAbility >= b.minimumTotalPointsToYellow,
    })

    if (level === 'e') {
      if (e.noPoints) color.e = 'grey'
      else if (e.oneTotalPointButMinimumTotalPointsToGreen) color.e = 'striped'
      else if (e.oneTotalPointButStudentHasZeroPoints) color.e = 'white'
      else if (e.moreThanOneTotalPointAndMinimumTotalPointsToGreen)
        color.e = 'green'
      else if (e.moreThanOneTotalPointAndMinimumTotalPointsToYellow)
        color.e = 'yellow'
      else {
        color.e = 'red'
        otg.e = 0 // e is not ok to green on total row as it is red in one or more formagor "not red above condition"
        otg.c = 0 // c is not ok to green on total row...
        otg.a = 0 // a is not ok to green on total row...
      }
    }

    const c = Object.freeze({
      noPointsAnd_C_A_MinimumPointsLimitExists:
        b.totalPoint === 0 && b.minimumPointsOnGradeOrHigherToGreen !== -1,
      oneTotalPointBut_C_A_MinimumPointsLimitAndMinimumTotalPointsToGreen:
        b.totalPoint === 1 &&
        b.minimumPointsOnGradeOrHigherToGreen !== -1 &&
        points.c + points.a >= b.minimumPointsOnGradeOrHigherToGreen &&
        totalPointsAbility >= b.minimumTotalPointsToGreen,
      oneTotalPointButStudentHasZero_C_APoints:
        b.totalPoint === 1 && points.c + points.a === 0,
      moreThanMinimumTotalPointsToGreenAndOverC_A_ToGreenLimit:
        totalPointsAbility >= b.minimumTotalPointsToGreen &&
        points.c + points.a >= b.minimumPointsOnGradeOrHigherToGreen,
      moreThanMinimumTotalPointsToYellowAndOverC_A_ToYellowLimit:
        totalPointsAbility >= b.minimumTotalPointsToYellow &&
        points.c + points.a >= b.minimumPointsOnGradeOrHigherToYellow &&
        (b.minimumPointsOnGradeOrHigherToYellow > 0 ||
          b.minimumPointsOnGradeOrHigherToYellow === -1),
      moreThanOneTotalPointAndOverMinimumTotalPointsToYellow:
        totalPointsAbility >= b.minimumTotalPointsToYellow,
    })
    if (level === 'c') {
      if (c.noPointsAnd_C_A_MinimumPointsLimitExists) color.c = 'grey'
      else if (
        c.oneTotalPointBut_C_A_MinimumPointsLimitAndMinimumTotalPointsToGreen
      ) {
        if (color.e === 'green' || color.e === 'grey' || color.e === 'white')
          color.c = 'striped'
        else if (color.e === 'striped') {
          color.e = 'green'
          color.c = 'striped'
        } else color.c = 'white'
      } else if (c.oneTotalPointButStudentHasZero_C_APoints) color.c = 'white'
      else if (c.moreThanMinimumTotalPointsToGreenAndOverC_A_ToGreenLimit) {
        color.e = 'green'
        color.c = 'green'
      } else if (c.moreThanMinimumTotalPointsToYellowAndOverC_A_ToYellowLimit) {
        if (color.e === 'red') {
          color.c = 'white'
        } else {
          color.c = 'yellow'
        }
      } else {
        color.c = 'white'
        otg.c = 0
        otg.a = 0
      }
    }
    const a = Object.freeze({
      noPointsAnd_C_A_MinimumPointsLimitExists:
        b.totalPoint === 0 && b.minimumPointsOnGradeOrHigherToGreen !== -1,
      oneTotalPointBut_A_MinimumPointsLimitAndMinimumTotalPointsToGreen:
        b.totalPoint === 1 &&
        b.minimumPointsOnGradeOrHigherToGreen !== -1 &&
        points.a >= b.minimumPointsOnGradeOrHigherToGreen &&
        totalPointsAbility >= b.minimumTotalPointsToGreen,
      oneTotalPointButStudentHasZero_APoints:
        b.totalPoint === 1 && points.a === 0,
      moreThanMinimumTotalPointsToGreenAndOverA_ToGreenLimit:
        totalPointsAbility >= b.minimumTotalPointsToGreen &&
        points.a >= b.minimumPointsOnGradeOrHigherToGreen,
      moreThanMinimumTotalPointsToYellowAndOverA_ToYellowLimit:
        totalPointsAbility >= b.minimumTotalPointsToYellow &&
        points.a >= b.minimumPointsOnGradeOrHigherToYellow &&
        (b.minimumPointsOnGradeOrHigherToYellow > 0 ||
          b.minimumPointsOnGradeOrHigherToYellow === -1),
    })
    if (level === 'a') {
      if (a.noPointsAnd_C_A_MinimumPointsLimitExists) color.a = 'grey'
      else if (
        a.oneTotalPointBut_A_MinimumPointsLimitAndMinimumTotalPointsToGreen
      ) {
        if (
          color.c === 'green' ||
          ((color.e === 'green' || color.e === 'grey') && color.c === 'grey')
        )
          color.a = 'striped'
        else if (color.c === 'striped') {
          color.c = 'green'
          color.a = 'striped'
        } else color.a = 'white'
      } else if (a.oneTotalPointButStudentHasZero_APoints) color.a = 'white'
      else if (a.moreThanMinimumTotalPointsToGreenAndOverA_ToGreenLimit) {
        color.e = 'green'
        color.c = 'green'
        color.a = 'green'
      } else if (a.moreThanMinimumTotalPointsToYellowAndOverA_ToYellowLimit) {
        color.a = 'yellow'
      } else {
        color.a = 'white'
        otg.a = 0
      }
    }
    if (totalRow) {
      if (otg.e === 0 && color.e === 'green') color.e = 'yellow'
      if (otg.c === 0 && color.c === 'green') color.c = 'yellow'
      if (otg.a === 0 && color.a === 'green') color.a = 'yellow'
    }
  })

  return { ...color }
}
