import { useTokenStore } from '@/stores/token'

export function useUnauthentication() {
  return {
    async beforeEnter(to, from, next) {
      const token = useTokenStore()
      if (!token.hasValidToken()) {
        next()
      } else {
        next('/home')
      }
    },
  }
}
