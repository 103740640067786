import { defineStore } from 'pinia'
import { useLocalStorage, type RemovableRef } from '@vueuse/core'

type TokenState = {
  token: RemovableRef<string | null>
  validTo: RemovableRef<string | null>
}

export const useKMTokenStore = defineStore('km-token', {
  state: () =>
    ({
      token: useLocalStorage('km-token', null),
      validTo: useLocalStorage('km-validTo', null),
    }) as TokenState,
  getters: {
    hasToken(): boolean {
      return !!this.token
    },
  },
  actions: {
    hasValidToken(): boolean {
      if (this.validTo) {
        const validToDate = new Date(this.validTo)
        if (validToDate < new Date()) {
          return false
        }
      }
      return !!this.token
    },
    setToken(token: string) {
      this.token = token
      // validTo is set to 3 hour and 50 min from now
      const validTo = new Date()
      validTo.setHours(validTo.getHours() + 3)
      validTo.setMinutes(validTo.getMinutes() + 50)
      this.validTo = validTo.toISOString()
    },
    clearToken() {
      // Not used in KM yet
      this.token = null
      this.validTo = null
    },
  },
})
