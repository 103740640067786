export function convertV1toV2limits(limitsV1) {
  return limitsV1.map((ability) => {
    return ability.map((limit, index) => {
      const levelPoints = limit[2]
      const minToLow = limit[0][0]
      const onLevelToLow = limit[0][1]
      const minToPass = limit[1][0]
      const onLevelToPass = limit[1][1]
      const v2limit = {
        levelPoints,
      }
      v2limit.pass = {
        min: minToPass,
      }
      v2limit.low = {
        min: minToLow,
      }
      if (index !== 0) {
        if (onLevelToPass > 0) {
          v2limit.pass.onLevelOrHigher = onLevelToPass
        } else if (onLevelToPass === 0) {
          v2limit.pass.disabled = true
        }
        if (onLevelToLow > 0) {
          v2limit.low.onLevelOrHigher = onLevelToLow
        } else if (onLevelToLow === 0) {
          v2limit.low.disabled = true
        }
      }
      return v2limit
    })
  })
}

export function convertV2toV1limits(limitsV2) {
  return limitsV2.map((ability) => {
    return ability.map((limit, index) => {
      const allowZeroPointsOnLevel = index === 0 ? 0 : -1
      const levelPoints = limit.levelPoints
      const minToPass = limit.pass.min
      const minToLow = limit.low.min
      let onLevelToPass = limit.pass.disabled ? 0 : limit.pass.onLevelOrHigher
      if (onLevelToPass === undefined) {
        onLevelToPass = allowZeroPointsOnLevel
      }
      let onLevelToLow = limit.low.disabled ? 0 : limit.low.onLevelOrHigher
      if (onLevelToLow === undefined) {
        onLevelToLow = allowZeroPointsOnLevel
      }

      return [[minToLow, onLevelToLow], [minToPass, onLevelToPass], levelPoints]
    })
  })
}
