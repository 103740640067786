import { useAdminAuthentication } from './hooks/routes/auth'
import { useAdminUnAuthentication } from './hooks/routes/unauth'

const AdminAuthView = () => import('./views/AuthView.vue')
const AdminLogin = () => import('./views/Login.vue')
const AdminMainView = () => import('./views/MainView.vue')
const AdminDashboard = () => import('./views/Dashboard.vue')
const AdminUsersVerify = () => import('./views/UsersVerify.vue')
const AdminUsersPreverify = () => import('./views/UsersPreverify.vue')
const AdminUsers = () => import('./views/Users.vue')
const AdminOrganizations = () => import('./views/Organizations.vue')
const AdminPublishedExams = () => import('./views/ExamsPublished.vue')
const AdminBooks = () => import('./views/Books.vue')
const AdminCurricula = () => import('./views/Curricula.vue')
const AdminCountries = () => import('./views/Countries.vue')
const AdminBookEdit = () => import('./views/BookEdit.vue')
const AdminCountiresEdit = () =>
  import('./views/countries/AdminCountriesEdit.vue')
const QuestionView = () => import('./views/QuestionView.vue')

export const routes = [
  {
    path: '/admauth',
    name: 'admauth',
    component: AdminAuthView,
    meta: { admin: true },
    children: [
      {
        path: '',
        name: 'admin-login',
        component: AdminLogin,
      },
    ],
    ...useAdminUnAuthentication(),
  },
  {
    path: '/admin/',
    name: 'admin',
    component: AdminMainView,
    meta: { admin: true },
    children: [
      {
        path: '',
        name: 'admin-dashboard',
        component: AdminDashboard,
      },
      {
        path: 'users',
        name: 'admin-users-list',
        component: AdminUsers,
      },
      {
        path: 'users/verify',
        name: 'admin-users-verify',
        component: AdminUsersVerify,
      },
      {
        path: 'users/preverify',
        name: 'admin-users-preverify',
        component: AdminUsersPreverify,
      },
      {
        path: 'organizations',
        name: 'admin-organizations-list',
        component: AdminOrganizations,
      },
      {
        path: 'exams/published',
        name: 'admin-published-exams',
        component: AdminPublishedExams,
      },
      {
        path: 'questions',
        name: 'admin-question-view',
        component: QuestionView,
      },
      {
        path: 'books',
        name: 'admin-books',
        component: AdminBooks,
      },
      {
        path: 'book/:bookId/edit',
        name: 'admin-book-edit',
        component: AdminBookEdit,
      },
      {
        path: 'courses',
        name: 'admin-courses',
        component: AdminCurricula,
      },
      {
        path: 'countries',
        name: 'admin-countries',
        component: AdminCountries,
      },
      {
        path: 'countries/:countryId/edit',
        name: 'admin-countries-edit',
        component: AdminCountiresEdit,
      },
    ],
    ...useAdminAuthentication(),
  },
]
