export function addPoints(
  a: DifficultyPoint,
  b: DifficultyPoint
): DifficultyPoint {
  return [a[0] + b[0], a[1] + b[1], a[2] + b[2]]
}

export function sum(arr: DifficultyPoint) {
  return arr.reduce((acc, val) => acc + val, 0)
}
