type PointTransformed = {
  ability: string
  points: number[] // (0 | 1)
}

export const getTotalGradePointsPerAbility = (
  pointTypes: string[],
  points: QuestionCriteria[]
) => {
  // =>                                    E  C  A
  // [{ability: 'Problemlösning', points: [0, 1, 0]}]
  // [{ability: 'Problemlösning', points: [1, 0, 0]}]
  const binaryPointsByType = points.map((item) => {
    return {
      ability: item.ability,
      points: pointTypes.map((pt) =>
        item.pointType === pt && item.points === 1 ? 1 : 0
      ),
    }
  })

  // =>                                    E  C  A
  // [{ability: 'Problemlösning', points: [1, 1, 0]}]
  const pointsByAbility = binaryPointsByType.reduce(
    (acc: PointTransformed[], pointItem: PointTransformed) => {
      const existingAbilityGroup = acc.find(
        (accItem: PointTransformed) => accItem.ability === pointItem.ability
      )
      if (existingAbilityGroup) {
        existingAbilityGroup.points = existingAbilityGroup.points.map(
          (p: number, index: number) => p + pointItem.points[index]
        )
      } else {
        acc.push(pointItem)
      }
      return acc
    },
    []
  )
  return pointsByAbility
}

export const getTotalPointsPerGrade = (
  pointTypes: string[],
  criterias: QuestionCriteria[]
) => {
  return getTotalGradePointsPerAbility(pointTypes, criterias || []).reduce(
    (acc, item) => {
      return acc.map((point, index) => point + item.points[index])
    },
    [0, 0, 0]
  )
}
