import { defineStore } from 'pinia'
import { useLocalStorage, type RemovableRef } from '@vueuse/core'

type TokenState = {
  token: RemovableRef<string | null>
  validTo: RemovableRef<string | null>
}

export const useAdminTokenStore = defineStore('admin-token', {
  state: () =>
    ({
      token: useLocalStorage('admin-token', null),
      validTo: useLocalStorage('admin-validTo', null),
    }) as TokenState,
  getters: {
    hasToken(): boolean {
      return !!this.token
    },
  },
  actions: {
    hasValidToken(): boolean {
      if (this.validTo) {
        const validToDate = new Date(this.validTo)
        if (validToDate < new Date()) {
          return false
        }
      }
      return !!this.token
    },
    setToken(token: string, validTo: Date) {
      this.token = token
      this.validTo = validTo.toISOString()
    },
    clearToken() {
      this.token = null
      this.validTo = null
    },
  },
})
